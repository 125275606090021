<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Referencing the titration curve below, what is the
        <stemble-latex content="$\text{Ka}$" /> of the acid? How do you know?
      </p>

      <p class="pl-6 mb-6">
        <v-img style="max-width: 429px" :src="imageName" />
      </p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.studentAnswer" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMiss116_PL9_Q2part4',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/ChemOleMiss116PL9Q4_header.png';
    },
  },
};
</script>
